<template>
  <img
    v-if="src && !loadFailed"
    :="{ ...$attrs, src }"
    class="rounded-full transition-all"
    @error="loadFailed = true"
  />
  <slot v-else>
    <Icon
      name="user-circle-solid"
      :="$attrs"
      class="text-gray-200 transition-all"
    />
  </slot>
</template>

<script setup lang="ts">
// defines
defineOptions({ inheritAttrs: false })
defineProps<{ src?: string }>()

const loadFailed = ref(false)
</script>
