<template>
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1583 6.20873C12.4527 6.14543 12.5621 6.1863 12.586 6.1978C12.5959 6.21833 12.6182 6.28567 12.6027 6.44453C12.5803 6.67517 12.4834 7.00153 12.2857 7.41003C11.8933 8.22103 11.1695 9.22103 10.1953 10.1953C9.22103 11.1695 8.22107 11.8933 7.41003 12.2857C7.00153 12.4834 6.67517 12.5802 6.44453 12.6027C6.2857 12.6182 6.21837 12.5958 6.1978 12.586C6.1863 12.5621 6.14543 12.4527 6.20873 12.1583C6.2854 11.8018 6.48963 11.3219 6.8346 10.7606C7.2525 10.0806 7.8499 9.3248 8.58737 8.58733C9.3248 7.8499 10.0806 7.25247 10.7606 6.83457C11.3219 6.48963 11.8018 6.2854 12.1583 6.20873ZM0.47063 8.74677L5.25323 13.5294C5.6271 13.9032 6.13423 13.9726 6.5739 13.9298C7.0195 13.8863 7.50493 13.721 7.99077 13.486C8.9684 13.0129 10.0873 12.1889 11.1381 11.1381C12.1889 10.0873 13.0129 8.9684 13.486 7.99077C13.721 7.50493 13.8863 7.0195 13.9298 6.5739C13.9726 6.13423 13.9032 5.6271 13.5294 5.25323L8.74677 0.470623C8.28423 0.00809336 7.6255 0.00857663 7.09537 0.122587C6.53603 0.242877 5.91113 0.528063 5.27987 0.916003C4.49927 1.39572 3.6627 2.06118 2.86193 2.86193C2.06118 3.66267 1.39572 4.49927 0.916007 5.27987C0.52807 5.91113 0.242883 6.53603 0.122593 7.09537C0.00857997 7.6255 0.00809669 8.28423 0.47063 8.74677Z"
      fill="currentColor"
    />
  </svg>
</template>
